<template>
  <div class="ActivityManageTab">
    <a-row :gutter="[15, 10]" style="margin: 5px 0">
      <a-col :span="5">
        <a-select
          style="width: 100%"
          class="item-select"
          v-model="searchForm.principalId"
          placeholder="品牌"
          show-search
          option-filter-prop="children"
          @change="mBrandChange"
          allowClear
        >
          <a-select-option v-for="item in brandList" :key="item.id">{{ item.principalName }}</a-select-option>
        </a-select>
      </a-col>
      <a-col :span="5">
        <a-select
          style="width: 100%"
          v-model="searchForm.carSeriesId"
          placeholder="车系"
          show-search
          option-filter-prop="children"
          allowClear
          @change="getDataList(true)"
        >
          <a-select-option v-for="item in searchSeriesList" :key="item.id">{{ item.name }}</a-select-option>
        </a-select>
      </a-col>
      <a-col :span="5">
        <a-range-picker
          :value="checkTimeRange"
          @change="onTimeChange"
          style="width: 100%"
          format="YYYY-MM-DD"
          value-format="YYYY-MM-DD"
          allowClear
        />
      </a-col>
    </a-row>
    <a-table :columns="columns" row-key="id" :pagination="false" :loading="tableLoading" :data-source="dataList">
      <div class="description_text" slot="promotionDescription" slot-scope="text, record">
        {{ text }}
      </div>
      <div slot="range" slot-scope="text, record">
        <template v-if="record.promotionRange === 'NATIONWIDE'"> 全国 </template>
        <template v-if="record.promotionRange === 'DEALER'"> {{ record.dealerList }} </template>
        <template v-if="record.promotionRange === 'PROVINCE'"> {{ record.areaList }} </template>
      </div>
      <div slot="state" slot-scope="text, record">
        <any-switch
          v-model="record.onShelves"
          :loading="switchLoading"
          :checkedVal="true"
          :unCheckedVal="false"
          @change="(check) => handleSwitch(check, record)"
        >
        </any-switch>
      </div>
      <div slot="operation" slot-scope="text, record">
        <a-space>
          <a-button type="link" @click="handleEdit(record)">编辑</a-button>
          <a-popconfirm
            placement="topLeft"
            title="确定删除该数据？"
            ok-text="确定"
            cancel-text="取消"
            @confirm="handleDelete(record.id)"
          >
            <a-button type="link" style="color: red">删除</a-button>
          </a-popconfirm>
        </a-space>
      </div>
    </a-table>
    <base-pagination
      :currentPage="pagination.current"
      :options="['30', '50', '100']"
      :pageSize="pagination.pageSize"
      :total="pagination.total"
      @onChange="handlePaginationChange"
      @onShowSizeChange="handlePaginationChange"
    />

    <ActivityUpdateModalComp ref="ActivityUpdateModalComp" :brandList="brandList" @success="getDataList(true)" />
  </div>
</template>

<script>
import api from '@/api/xhsAgencyApi';
import AnySwitch from '@/components/AnySwitch/AnySwitch.vue';
import ActivityUpdateModalComp from '@/pages/app/xhsAgency/aigc/components/ActivityUpdateModalComp.vue';

export default {
  name: 'ActivityManageTab',
  components: { AnySwitch, ActivityUpdateModalComp },
  data() {
    return {
      searchForm: {
        principalId: undefined,
        carSeriesId: undefined,
      },
      brandList: [],
      searchSeriesList: [],
      // carSeriesIdList: [],
      pagination: {
        current: 1,
        pageSize: 30,
        total: 1,
      },
      dataList: [],
      tableLoading: false,
      columns: [
        {
          align: 'center',
          title: '品牌',
          width: 120,
          dataIndex: 'principalName',
        },
        {
          align: 'center',
          title: '车系',
          width: 120,
          dataIndex: 'carSeriesArr',
          customRender(record) {
            let carSeriesName = '';
            if (record.length) {
              carSeriesName = record.map((item) => item.carSeriesName).join(',');
            }
            return carSeriesName;
          },
        },
        {
          align: 'center',
          title: '活动名称',
          dataIndex: 'promotionName',
        },
        {
          align: 'center',
          title: '描述',
          width: 220,
          dataIndex: 'promotionDescription',
          scopedSlots: { customRender: 'promotionDescription' },
        },
        {
          align: 'center',
          title: '活动参与范围',
          width: 180,
          scopedSlots: { customRender: 'range' },
        },
        {
          align: 'center',
          title: '有效时间',
          width: 200,
          customRender(record) {
            return `${record.promotionStartTime ? record.promotionStartTime.split(' ')[0] : '-'} 至 ${
              record.promotionEndTime ? record.promotionEndTime.split(' ')[0] : '-'
            }`;
          },
        },
        {
          title: '上架',
          width: 100,
          align: 'center',
          scopedSlots: { customRender: 'state' },
        },
        {
          align: 'center',
          title: '操作',
          scopedSlots: { customRender: 'operation' },
        },
      ],
      switchLoading: false,
      checkTimeRange: [],
    };
  },
  created() {
    this.getDataList(true);
    this.getBrandList();
  },
  mounted() {},
  methods: {
    getDataList(init = false) {
      if (init) this.pagination.current = 1;
      const params = {
        ...this.searchForm,
        page: this.pagination.current,
        size: this.pagination.pageSize,
        startDate: this.checkTimeRange[0],
        endDate: this.checkTimeRange[1],
      };
      this.tableLoading = true;
      api
        .getXhsActivityInfoList(params)
        .then(({ data, code, message }) => {
          if (code === 200) {
            this.dataList = data.list.map((v) => {
              let areaList = '';
              let dealerList = '';
              if (v.promotionRange === 'PROVINCE') {
                let strArr = [];
                v.cityArr?.forEach((value) => {
                  strArr.push(value.cityName);
                });
                if (strArr.length > 0) {
                  areaList = strArr.reduce((prev, item) => {
                    prev += `、${item}`;
                    return prev;
                  });
                } else {
                  areaList = '-';
                }
              }
              if (v.promotionRange === 'DEALER') {
                let strArr = [];
                v.dealerArr?.forEach((value) => {
                  strArr.push(value.memberName);
                });
                if (strArr.length > 0) {
                  dealerList = strArr.reduce((prev, item) => {
                    prev += `、${item}`;
                    return prev;
                  });
                } else {
                  dealerList = '-';
                }
              }
              return {
                ...v,
                areaList,
                dealerList,
              };
            });
            this.pagination.total = data.total;
          } else {
            this.$message.error(message);
          }
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
    async getBrandList() {
      api.getBrandList().then(({ data, code, message }) => {
        if (code === 200) {
          this.brandList = data;
        } else {
          this.$message.error(message);
        }
      });
    },
    mBrandChange(value) {
      this.searchForm.carSeriesId = undefined;
      this.searchSeriesList = [];
      if (value) {
        api.getNewTypeList({ principalIds: value }).then((res) => {
          if (res.code === 200) {
            this.searchSeriesList = res.data;
          } else {
            this.$message.error(res.message);
          }
        });
      }
      this.getDataList(true);
    },
    handlePaginationChange(current, pageSize) {
      this.pagination.current = current;
      this.pagination.pageSize = pageSize;
      this.getDataList();
    },
    async handleEdit(data) {
      this.$refs.ActivityUpdateModalComp.handleEdit(data);
    },
    async handleDelete(id) {
      const { code, message } = await api.deleteActivity(id);
      if (code === 200) {
        this.$message.success('删除成功');
        if (this.dataList.length === 1 && this.pagination.current > 1) {
          this.pagination.current -= 1;
        }
        this.getDataList(true);
      } else {
        this.$message.error(message);
      }
    },
    handleOpen() {
      this.$refs.ActivityUpdateModalComp.handleOpen();
    },
    async handleSwitch(onShelves, { id }) {
      this.switchLoading = true;
      const params = {
        id,
        onShelves,
      };
      const { code, message } = await api.changeActivityStatus(params).finally(() => (this.switchLoading = false));
      if (code === 200) {
        this.$message.success('操作成功');
      } else {
        this.$message.error(message);
        this.getDataList();
      }
    },
    async onTimeChange(dates, dateStrings) {
      this.checkTimeRange = [dates[0], dates[1]];
      this.getDataList(true);
    },
  },
};
</script>

<style lang="less" scoped>
.description_text {
  width: 220px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:hover {
    white-space: normal;
    overflow: visible;
  }
}

/deep/ .ant-form-item {
  margin-bottom: 3px !important;
}
</style>
