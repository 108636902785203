<template>
  <div class="hit_word">
    <a-row :gutter="[15, 10]" style="margin: 5px 0">
      <a-col :span="5">
        <a-input-search
          v-model="search.word"
          placeholder="搜索关键词"
          @search="getDataList(true)"
          allowClear
        ></a-input-search>
      </a-col>
    </a-row>
    <a-table :columns="columns" row-key="id" :pagination="false" :loading="tableLoading" :data-source="dataList" @change="onTableChange">
      <div slot="operation" slot-scope="text, record">
        <a-space>
          <a v-clipboard:copy="record.word" v-clipboard:success="onCopy" v-clipboard:error="onCopyError">复制</a>
          <a-popconfirm
            placement="topLeft"
            title="确定删除该数据？"
            ok-text="确定"
            cancel-text="取消"
            @confirm="handleDelete(record.id)"
          >
            <a-button type="link" style="color: red">删除</a-button>
          </a-popconfirm>
        </a-space>
      </div>
    </a-table>
    <base-pagination
      :currentPage="pagination.current"
      :options="['30', '50', '100']"
      :pageSize="pagination.pageSize"
      :total="pagination.total"
      @onChange="handlePaginationChange"
      @onShowSizeChange="handlePaginationChange"
    />

    <a-modal
      v-model="showModal"
      width="700px"
      title="添加关键词"
      :confirmLoading="saveLoading"
      okText="保存"
      @cancel="handleClose"
      @ok="handleOk"
    >
      <div style="text-align: right">已输入 {{ addCount }} 个</div>
      <a-textarea
        v-model.trim="addText"
        placeholder="输入关键词，每个关键词一行"
        :auto-size="{ minRows: 20, maxRows: 20 }"
      ></a-textarea>
      <p>1、支持复制粘贴方式新增，词与词之间用换行符分隔，每行一个关键词，单个词长度 30 个字以内。</p>
      <p>2、最多 100 行</p>
      <p>3、已添加过的关键词会自动忽略</p>
    </a-modal>
  </div>
</template>

<script>
import api from '@/api/xhsAgencyApi';

export default {
  name: 'HitWordManageTab',
  data() {
    return {
      search: {
        word: undefined,
        atimeDesc: true,
      },
      dataList: [],
      tableLoading: false,
      columns: [
        {
          align: 'center',
          title: 'ID',
          dataIndex: 'id',
          width: 180,
        },
        {
          align: 'center',
          title: '关键词',
          dataIndex: 'word',
        },
        {
          align: 'center',
          title: '操作人',
          customRender(record) {
            return record.actor || '-';
          },
        },
        {
          align: 'center',
          title: '操作时间',
          dataIndex: 'atime',
          sorter: true,
          sortDirections: ['descend', 'ascend', 'descend'],
          defaultSortOrder: 'descend',
        },
        {
          align: 'center',
          title: '操作',
          scopedSlots: { customRender: 'operation' },
        },
      ],
      pagination: {
        current: 1,
        pageSize: 30,
        total: 1,
      },
      showModal: false,
      addText: undefined,
      saveLoading: false,
    };
  },
  computed: {
    addCount() {
      return this.addText ? this.addText.split('\n').filter((v) => v).length : 0;
    },
  },
  created() {
    this.getDataList(true);
  },
  methods: {
    getDataList(init = false) {
      if (init) this.pagination.current = 1;
      const params = {
        word: this.search.word,
        atimeDesc: this.search.atimeDesc,
        page: this.pagination.current,
        size: this.pagination.pageSize,
      };
      this.tableLoading = true;
      api
        .getHitWordList(params)
        .then(({ data, code, message }) => {
          if (code === 200) {
            this.dataList = data.list;
            this.pagination.total = data.total;
            console.log(data);
          } else {
            this.$message.error(message);
          }
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
    async handleDelete(id) {
      const { code, message } = await api.deleteHitWord(id);
      if (code === 200) {
        this.$message.success('删除成功');
        if (this.dataList.length === 1 && this.pagination.current > 1) {
          this.pagination.current--;
        }
        this.getDataList();
      } else {
        this.$message.error(message);
      }
    },
    handlePaginationChange(current, pageSize) {
      this.pagination.current = current;
      this.pagination.pageSize = pageSize;
      this.getDataList();
    },
    handleOpen() {
      this.showModal = true;
    },
    async handleOk() {
      if (!this.addText) return this.$message.error('请输入关键词');
      const keywords = this.addText.split('\n').filter((v) => v);
      if (keywords.length > 100) return this.$message.error('关键词最多100个');
      let hasOver = false;
      keywords.forEach((words) => {
        if (words.length > 30) return (hasOver = true);
      });
      if (hasOver) return this.$message.error('每个关键词最多30个字');
      this.saveLoading = true;
      const { code, message } = await api.addHitWord({ keywords: this.addText }).finally(() => {
        this.saveLoading = false;
      });
      if (code === 200) {
        this.$message.success('添加成功');
        this.getDataList(true);
        this.handleClose();
      } else {
        this.$message.error(message);
      }
    },
    handleClose() {
      this.showModal = false;
      this.addText = undefined;
    },
    onCopy() {
      this.$message.success('复制成功');
    },
    onCopyError() {
      this.$message.error('复制失败，该浏览器不支持自动复制');
    },
    onTableChange(pagination, filters, sorter) {
      const { order, field } = sorter;
      this.search.atimeDesc = !order ? true : order == 'descend' ? true : false;
      this.getDataList(true);
    },
  },
};
</script>

<style lang="less" scoped></style>
