<template>
  <div>
    <a-tabs v-model="tabKey" size="large">
      <a-tab-pane v-for="item in tabs" :key="item.value" :tab="item.label"></a-tab-pane>
      <a-button slot="tabBarExtraContent" type="primary" @click="handleAdd"> 添加 </a-button>
    </a-tabs>
    <SellingManageTab ref="SellingManageTab" v-if="tabKey === 1" />
    <ActivityManageTab ref="ActivityManageTab" v-if="tabKey === 2" />
    <HitWordManageTab ref="HitWordManageTab" v-if="tabKey === 3" />
  </div>
</template>

<script>
import SellingManageTab from './components/SellingManageTab.vue';
import ActivityManageTab from './components/ActivityManageTab.vue';
import HitWordManageTab from './components/HitWordManageTab.vue';

export default {
  name: 'AiWritingManage',
  components: { SellingManageTab, ActivityManageTab, HitWordManageTab },
  data() {
    const tabs = [
      {
        value: 1,
        label: '卖点',
      },
      {
        value: 2,
        label: '优惠活动',
      },
      {
        value: 3,
        label: '爆炸词',
      },
    ];
    return {
      tabs,
      tabKey: 1,
    };
  },
  methods: {
    handleAdd() {
      switch (this.tabKey) {
        case 1: {
          this.$refs.SellingManageTab.handleOpen();
          break;
        }
        case 2: {
          this.$refs.ActivityManageTab.handleOpen();
          break;
        }
        case 3: {
          this.$refs.HitWordManageTab.handleOpen();
          break;
        }
        default: {
          break;
        }
      }
    },
  },
};
</script>

<style lang="less" scoped></style>
