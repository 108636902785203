<template>
  <div class="sellingManage">
    <a-row :gutter="[15, 10]" style="margin: 5px 0">
      <a-col :span="5">
        <a-select
          style="width: 100%"
          class="item-select"
          v-model="searchForm.principalId"
          placeholder="品牌"
          show-search
          option-filter-prop="children"
          @change="mBrandChange"
          allowClear
        >
          <a-select-option v-for="item in brandList" :key="item.id">{{ item.principalName }}</a-select-option>
        </a-select>
      </a-col>
      <a-col :span="5">
        <a-select
          style="width: 100%"
          v-model="searchForm.carSeriesId"
          placeholder="车系"
          show-search
          option-filter-prop="children"
          allowClear
          @change="getDataList(true)"
        >
          <a-select-option v-for="item in searchSeriesList" :key="item.id">{{ item.name }}</a-select-option>
        </a-select>
      </a-col>
      <a-col :span="6">
        <a-input-search
          v-model="searchForm.search"
          placeholder="搜索核心卖点、产品/服务、描述"
          @search="getDataList(true)"
          allowClear
        ></a-input-search>
      </a-col>
    </a-row>
    <a-row style="margin-bottom: 10px">
      <span style="margin-right: 30px">核心卖点数： {{ coreSellingPointCount }}</span>
      <span>产品/服务： {{ productFeatureCount }}</span>
    </a-row>
    <a-table :columns="columns" row-key="id" :pagination="false" :loading="tableLoading" :data-source="dataList" @change="onTableChange">
      <div class="description_text" slot="productDescription" slot-scope="text, record">
        {{ text }}
      </div>
      <div slot="operation" slot-scope="text, record">
        <a-space>
          <a-button type="link" @click="handleEdit(record)">编辑</a-button>
          <a-popconfirm
            placement="topLeft"
            title="确定删除该数据？"
            ok-text="确定"
            cancel-text="取消"
            @confirm="handleDelete(record.id)"
          >
            <a-button type="link" style="color: red">删除</a-button>
          </a-popconfirm>
        </a-space>
      </div>
    </a-table>
    <base-pagination
      :currentPage="pagination.current"
      :options="['30', '50', '100']"
      :pageSize="pagination.pageSize"
      :total="pagination.total"
      @onChange="handlePaginationChange"
      @onShowSizeChange="handlePaginationChange"
    />

    <a-modal
      v-model="showModal"
      width="700px"
      :title="`${isEdit ? '编辑' : '新增'}卖点`"
      :confirmLoading="saveLoading"
      okText="保存"
      @cancel="handleClose"
      @ok="handleOk"
    >
      <a-form-model :labelCol="{ span: 4 }" :wrapperCol="{ span: 18 }" :rules="rules" ref="addFormRef" :model="addForm">
        <a-form-model-item label="品牌" prop="principalId">
          <a-select
            style="width: 100%"
            class="item-select"
            v-model="addForm.principalId"
            placeholder="请选择品牌"
            show-search
            option-filter-prop="children"
            @change="getTypeList"
            allowClear
            :disabled="isEdit"
          >
            <a-select-option v-for="item in brandList" :key="item.id">{{ item.principalName }}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="车系" prop="carSeriesId">
          <a-select
            style="width: 100%"
            v-model="addForm.carSeriesId"
            placeholder="请选择车系"
            show-search
            option-filter-prop="children"
            allowClear
            :disabled="isEdit"
          >
            <a-select-option v-for="item in carSeriesIdList" :key="item.id">{{ item.name }}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="核心卖点" prop="coreSellingPoint">
          <a-input style="width: 100%" v-model="addForm.coreSellingPoint" placeholder="请输入核心卖点" />
        </a-form-model-item>
        <a-form-model-item label="产品/服务" prop="productFeature">
          <a-input style="width: 100%" v-model="addForm.productFeature" placeholder="请输入产品/服务" />
        </a-form-model-item>
        <a-form-model-item label="描述" prop="productDescription">
          <a-textarea
            style="width: 100%"
            v-model="addForm.productDescription"
            placeholder="请输入描述"
            :auto-size="{ minRows: 3, maxRows: 7 }"
          />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import api from '@/api/xhsAgencyApi';

export default {
  name: 'sellingManage',
  props: {},
  components: {},
  data() {
    return {
      searchForm: {
        search: undefined,
        principalId: undefined,
        carSeriesId: undefined,
        atimeDesc: true,
      },
      brandList: [],
      searchSeriesList: [],
      carSeriesIdList: [],
      pagination: {
        current: 1,
        pageSize: 30,
        total: 1,
      },
      dataList: [],
      tableLoading: false,
      coreSellingPointCount: 0,
      productFeatureCount: 0,
      columns: [
        {
          align: 'center',
          width: 100,
          title: '品牌',
          dataIndex: 'principalName',
        },
        {
          align: 'center',
          width: 100,
          title: '车系',
          dataIndex: 'carSeriesName',
        },
        {
          align: 'center',
          title: '核心卖点',
          dataIndex: 'coreSellingPoint',
        },
        {
          align: 'center',
          width: 200,
          title: '产品/服务',
          dataIndex: 'productFeature',
          ellipsis: true,
        },
        {
          align: 'center',
          title: '描述',
          dataIndex: 'productDescription',
          scopedSlots: { customRender: 'productDescription' },
          // ellipsis: true,
        },
        {
          align: 'center',
          title: '操作人',
          customRender(record) {
            return record.actor || '-';
          },
        },
        {
          align: 'center',
          title: '操作时间',
          dataIndex: 'atime',
          sorter: true,
          sortDirections: ['descend', 'ascend', 'descend'],
          defaultSortOrder: 'descend',
        },
        {
          align: 'center',
          title: '操作',
          scopedSlots: { customRender: 'operation' },
        },
      ],
      addForm: {
        id: undefined,
        carSeriesId: undefined,
        principalId: undefined,
        coreSellingPoint: undefined,
        productDescription: undefined,
        productFeature: undefined,
      },
      isEdit: false,
      showModal: false,
      saveLoading: false,
      rules: {
        principalId: [{ required: true, message: '请选择品牌', trigger: 'change' }],
        carSeriesId: [{ required: true, message: '请选择车系', trigger: 'change' }],
        coreSellingPoint: [{ required: true, message: '请输入核心卖点', trigger: 'blur' }],
        productFeature: [{ required: true, message: '请输入产品/服务', trigger: 'blur' }],
        productDescription: [{ required: true, message: '请输入描述', trigger: 'blur' }],
      },
    };
  },
  created() {
    this.getDataList(true);
    this.getBrandList();
  },
  mounted() {},
  methods: {
    getDataList(init = false) {
      if (init) this.pagination.current = 1;
      const params = {
        ...this.searchForm,
        page: this.pagination.current,
        size: this.pagination.pageSize,
      };
      this.tableLoading = true;
      api
        .getXhsCarSeriesSellPointList(params)
        .then(({ data, code, message }) => {
          if (code === 200) {
            this.dataList = data.list;
            this.pagination.total = data.total;
            console.log(data);
          } else {
            this.$message.error(message);
          }
        })
        .finally(() => {
          this.tableLoading = false;
        });
      this.getInfo(params);
    },
    async getInfo(params) {
      const { code,data, message } = await api.getSellPointInfo(params);
      if(code === 200){
        this.coreSellingPointCount = data.coreSellingPointCount;
        this.productFeatureCount = data.productFeatureCount;
      }else{
        this.$message.error(message);
      }
    },
    async getBrandList() {
      api.getBrandList().then(({ data, code, message }) => {
        if (code === 200) {
          this.brandList = data;
        } else {
          this.$message.error(message);
        }
      });
    },
    mBrandChange(value) {
      this.searchForm.carSeriesId = undefined;
      this.searchSeriesList = [];
      if (value) {
        api.getNewTypeList({ principalIds: value }).then((res) => {
          if (res.code === 200) {
            this.searchSeriesList = res.data;
          } else {
            this.$message.error(res.message);
          }
        });
      }
      this.getDataList(true);
    },
    handlePaginationChange(current, pageSize) {
      this.pagination.current = current;
      this.pagination.pageSize = pageSize;
      this.getDataList();
    },
    handleEdit(data) {
      this.isEdit = true;
      this.showModal = true;
      const { id, principalId, carSeriesId, coreSellingPoint, productFeature, productDescription, carSeriesName } =
        data;
      Object.assign(this.addForm, {
        id,
        principalId,
        carSeriesId,
        coreSellingPoint,
        productFeature,
        productDescription,
      });
      this.carSeriesIdList = [{ id: carSeriesId, name: carSeriesName }];
    },
    async handleDelete(id) {
      const { code, message } = await api.deleteSellPoint(id);
      if (code === 200) {
        this.$message.success('删除成功');
        if (this.dataList.length === 1 && this.pagination.current > 1) {
          this.pagination.current -= 1;
        }
        this.getDataList();
      } else {
        this.$message.error(message);
      }
    },
    handleOpen() {
      this.showModal = true;
    },
    handleClose() {
      this.$refs.addFormRef && this.$refs.addFormRef.clearValidate();
      Object.assign(this.addForm, this.$options.data().addForm);
      this.carSeriesIdList = [];
      this.showModal = false;
      this.isEdit = false;
    },
    handleOk() {
      this.$refs.addFormRef.validate(async (valid) => {
        if (valid) {
          this.saveLoading = true;
          const params = {
            ...this.addForm,
            carSeriesName: this.carSeriesIdList.find((item) => item.id === this.addForm.carSeriesId)?.name,
            principalName: this.brandList.find((item) => item.id === this.addForm.principalId)?.principalName,
          };
          console.log('params ->', params);
          const { code, message } = await api.updateSellPoint(params).finally(() => (this.saveLoading = false));
          if (code === 200) {
            this.$message.success('操作成功');
            this.handleClose();
            this.getDataList(true);
          } else {
            this.$message.error(message);
          }
        }
      });
    },
    getTypeList(value) {
      Object.assign(this.addForm, this.$options.data().addForm);
      this.addForm.principalId = value;
      if (!value) return (this.carSeriesIdList = []);
      return new Promise((resolve) => {
        api.getNewTypeList({ principalIds: this.addForm.principalId }).then((res) => {
          if (res.code === 200) {
            this.carSeriesIdList = res.data;
            resolve(true);
          } else {
            this.$message.error(res.message);
          }
        });
      });
    },
    onTableChange(pagination, filters, sorter) {
      const { order, field } = sorter;
      this.searchForm.atimeDesc = !order ? true : order == 'descend' ? true : false;
      this.getDataList(true);
    },
  },
};
</script>

<style lang="less" scoped>
.description_text {
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:hover {
    white-space: normal;
    overflow: visible;
  }
}
</style>
