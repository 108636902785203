<template>
  <a-select
    v-model="inputValue"
    @change="handleChange"
    @dropdownVisibleChange="getStoreList()"
    @popupScroll="handlePopupScroll"
    v-bind="$attrs"
    v-on="$listeners"
    @search="getStoreList"
    :loading="loading"
  >
    <a-select-option
      v-for="item in storeList"
      :key="item.id"
      :value="hasName ? `${item.id},${item.matrixMemberName}` : item.id"
      >{{ item.matrixMemberName }}</a-select-option
    >
    <a-spin v-if="loading" slot="notFoundContent" size="small" />
  </a-select>
</template>

<script>
import api from '@/api/xhsAgencyApi';
import debounce from 'lodash/debounce';

export default {
  props: {
    value: {
      type: [String, Array],
    },
    principalIds: {
      type: Array,
      default: () => [],
    },
    allEmptySearch: {
      type: Boolean,
      default: false,
    },
    hasName: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    this.getStoreList = debounce(this.getStoreList, 500);
    return {
      loading: false,
      inputValue: undefined,
      storeList: [],
      pagination: {
        page: 1,
        size: 20,
        total: 0,
      },
      filter: '',
    };
  },
  watch: {
    value(val) {
      this.inputValue = val;
    },
    principalIds: {
      handler() {
        this.pagination.page = 1;
        this.storeList = [];
        this.getStoreList();
      },
      deep: true,
    },
  },
  methods: {
    async getStoreList(filter) {
      // 是否存在品牌
      const params = {};
      if (this.principalIds && this.principalIds.length) {
        params.principalIds = this.principalIds.join(',');
      } else {
        this.storeList = [];
        return;
      }
      if (this.filter !== filter) {
        this.pagination.page = 1;
        this.filter = filter;
      }
      this.loading = true;
      try {
        const { code, data, message } = await api.getStoreList({
          ...params,
          ...this.pagination,
          matrixMemberName: filter,
        });
        if (code === 200) {
          this.pagination.total = data.total;
          if (this.pagination.page === 1) {
            this.storeList = data.list;
          } else {
            this.storeList = [...this.storeList, ...data.list];
          }
        } else {
          return this.$message.error(`${message}`);
        }
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.$message.error('操作失败');
      }
    },
    handleChange(val) {
      this.$emit('input', val);
      this.$emit('change', val); // 避免使用@change 父组件会触发两次
      this.$emit('onChange', val);
      let list = []
      if(val && val.length > 0){
        this.storeList.forEach(e => {
          if( val.indexOf(e.id) > -1) list.push(e)
        });
      }
      this.$emit('getFullData', list);
    },
    /* 下拉滚动 */
    async handlePopupScroll(e) {
      //已经有的下拉项目 大于等于后台返回的下拉项总长度,那么就返回不请求了
      if (this.loading || this.storeList.length >= this.pagination.total) return;
      const { scrollHeight, scrollTop, clientHeight } = e.target;
      if (scrollHeight - scrollTop - clientHeight < 20) {
        // 到达了底部,请求数据
        this.pagination.page++; //分页器自增1
        this.getStoreList();
      }
    },
  },
  created() {
    this.inputValue = this.value;
    this.getStoreList();
  },
};
</script>

<style scoped lang="scss"></style>
