<template>
  <div class="update-modal-comp">
    <a-modal
      v-model="showModal"
      width="700px"
      :title="`${isEdit ? '编辑' : '新增'}活动`"
      :confirmLoading="saveLoading"
      okText="保存"
      @cancel="handleClose"
      @close="handleClose"
      @ok="handleOk"
    >
      <a-form-model :labelCol="{ span: 4 }" :wrapperCol="{ span: 18 }" :rules="rules" ref="addFormRef" :model="addForm">
        <a-form-model-item label="品牌" prop="principalId" v-show="!fixedBrand">
          <a-select
            class="item-select"
            v-model="addForm.principalId"
            placeholder="请选择品牌"
            show-search
            option-filter-prop="children"
            @change="getTypeList"
          >
            <a-select-option v-for="item in brandList" :key="item.id">{{ item.principalName }}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="车系" prop="carSeriesArr" v-show="!fixedBrand">
          <a-select
            v-model="addForm.carSeriesArr"
            mode="multiple"
            placeholder="请选择车系"
            show-search
            option-filter-prop="children"
            allowClear
          >
            <a-select-option v-for="item in carSeriesIdList" :key="item.id">{{ item.name }}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="活动名称" prop="promotionName">
          <a-input allowClear v-model.trim="addForm.promotionName" :maxLength="30"></a-input>
        </a-form-model-item>
        <a-form-model-item label="描述" prop="promotionDescription">
          <a-textarea
            allowClear
            v-model.trim="addForm.promotionDescription"
            :maxLength="300"
            :auto-size="{ minRows: 3, maxRows: 8 }"
          ></a-textarea>
        </a-form-model-item>
        <a-form-model-item label="活动参与范围" prop="promotionRange">
          <a-radio-group v-model="addForm.promotionRange">
            <a-radio v-for="item in rangeList" :key="item.value" :value="item.value">{{ item.label }} </a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item label="地区" prop="cityArr" v-if="addForm.promotionRange === 'PROVINCE'">
          <a-cascader
            style="width: 100%"
            :options="options"
            :display-render="displayRender"
            :show-search="{ areaFilter }"
            placeholder="请选择地区"
            :value="addForm.cityArr"
            @change="onAreaChange"
            change-on-select
          />
        </a-form-model-item>
        <a-form-model-item label="门店" prop="dealerArr" v-if="addForm.promotionRange === 'DEALER'">
          <StoreSelectInput
            ref="StoreSelectInput"
            style="width: 100%"
            :principalIds="[addForm.principalId]"
            v-model="addForm.dealerArr"
            placeholder="请选择门店"
            show-search
            mode="multiple"
            :filter-option="false"
            allowClear
            @getFullData="handleStoreChange"
          />
        </a-form-model-item>
        <a-form-model-item label="有效时间" prop="validTime">
          <a-range-picker v-model="addForm.validTime" format="YYYY-MM-DD" value-format="YYYY-MM-DD" allowClear />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import StoreSelectInput from '@/components/xhsAgencyTool/StoreSelectInput';
import api from '@/api/xhsAgencyApi';

export default {
  name: '',
  computed: {},
  watch: {},
  created() {
    this.getAreaData();
  },
  mounted() {},
  props: {
    brandList: {
      type: Array,
      default: () => [],
    },
    fixedBrand: {
      type: Boolean,
      default: () => false,
    },
  },
  components: { StoreSelectInput },
  data() {
    return {
      showModal: false,
      saveLoading: false,
      addForm: {
        id: undefined,
        principalId: undefined,
        carSeriesArr: undefined,
        promotionName: '',
        promotionDescription: '',
        validTime: [],
        dealerArr: undefined,
        cityArr: undefined,
        promotionRange: undefined,
      },
      isEdit: false,
      rules: {
        principalId: [{ required: true, message: '请选择品牌', trigger: 'change' }],
        carSeriesArr: [{ required: true, message: '请选择车系', trigger: 'change' }],
        promotionName: [{ required: true, message: '请输入活动名称', trigger: 'blur' }],
        promotionDescription: [{ required: true, message: '请输入活动描述', trigger: 'blur' }],
        validTime: [{ required: true, message: '请选择有效时间', trigger: 'change' }],
        dealerArr: [{ required: true, message: '请选择门店', trigger: 'change' }],
        cityArr: [{ required: true, message: '请选择城市', trigger: 'change' }],
        promotionRange: [{ required: true, message: '请选择活动参与范围', trigger: 'change' }],
      },
      allShopList: [],
      options: [],
      allAreaList: [],
      carSeriesIdList: [],
      rangeList: [
        {
          value: 'NATIONWIDE',
          label: '全国',
        },
        {
          value: 'PROVINCE',
          label: '省份-城市',
        },
        {
          value: 'DEALER',
          label: '门店',
        },
      ],
      fixedData: {},
    };
  },
  methods: {
    handleOpen() {
      this.showModal = true;
    },
    handleAddFixedActivity(fixedData) {
      this.fixedData = fixedData;
      this.addForm.principalId = fixedData.principalId;
      this.addForm.carSeriesArr = fixedData.carSeriesId;
      this.showModal = true;
    },
    handleClose() {
      this.$refs.addFormRef && this.$refs.addFormRef.clearValidate();
      Object.assign(this.addForm, this.$options.data().addForm);
      this.carSeriesIdList = [];
      this.showModal = false;
      this.isEdit = false;
    },
    async handleEdit(data) {
      const {
        id,
        promotionRange,
        promotionName,
        principalId,
        promotionDescription,
        promotionStartTime,
        promotionEndTime,
      } = data;
      console.log('data ->', data);
      Object.assign(this.addForm, {
        id,
        promotionRange,
        promotionName,
        principalId,
        promotionDescription,
        carSeriesArr: [],
        validTime: [promotionStartTime.split(' ')[0], promotionEndTime.split(' ')[0]],
      });
      data.carSeriesArr.forEach((c) => {
        this.addForm.carSeriesArr.push(c.carSeriesId);
      });
      api.getNewTypeList({ principalIds: this.addForm.principalId }).then((res) => {
        if (res.code === 200) {
          this.carSeriesIdList = res.data;
        } else {
          this.$message.error(res.message);
        }
      });
      if (data.dealerArr?.length) {
        const list = [];
        this.addForm.dealerArr = [];
        data.dealerArr.forEach((c) => {
          this.addForm.dealerArr.push(c.memberId);
          list.push({
            id: c.memberId,
            matrixMemberName: c.memberName,
            matrixMemberCode: c.memberCode,
          });
        });

        list.forEach((l) => {
          const index = this.allShopList.findIndex((s) => s.id === l.index);
          if (index > -1) {
            this.shopList.push(l);
          }
        });
      }
      if (data.cityArr?.length) {
        const current = this.allAreaList.find((v) => v.id === data.cityArr[0].cityId);
        if (current.level == 2) {
          this.addForm.cityArr = [current.parent_id, data.cityArr[0].cityId];
        } else {
          this.addForm.cityArr = [data.cityArr[0].cityId];
        }
      }
      this.isEdit = true;
      this.showModal = true;
    },
    handleOk() {
      this.$refs.addFormRef.validate(async (valid) => {
        if (valid) {
          this.saveLoading = true;
          const params = {
            ...this.addForm,
            promotionStartTime: this.addForm.validTime[0],
            promotionEndTime: this.addForm.validTime[1],
          };
          params.principalName = this.fixedBrand
            ? this.fixedData.principalName
            : this.brandList.find((item) => item.id === params.principalId)?.principalName;
          const carSeriesArr = this.carSeriesIdList
            .filter((item) => params.carSeriesArr.includes(item.id))
            .map((v) => {
              return {
                carSeriesId: v.id,
                carSeriesName: v.name,
                principalId: v.brandId,
                principalName: params.principalName,
              };
            });
          params.carSeriesArr = this.fixedBrand ? [{ ...this.fixedData }] : carSeriesArr;
          if (params.cityArr?.length) {
            if (params.cityArr.length > 1) {
              params.cityArr.splice(0, 1);
            }
            const find = this.allAreaList.find((v) => v.id === params.cityArr[0]);
            params.cityArr = [
              {
                cityId: find?.id || undefined,
                cityName: find?.name || undefined,
              },
            ];
          }
          if (params.dealerArr?.length) {
            const findList = this.allShopList.filter((v) => params.dealerArr.includes(v.id));
            params.dealerArr = findList.map((f) => {
              return {
                memberCode: f.matrixMemberCode,
                memberId: f.id,
                memberName: f.matrixMemberName,
              };
            });
          }
          delete params.validTime;
          console.log('params ->', params);
          const { code, message } = await api.addOrUpdateActivity(params).finally(() => (this.saveLoading = false));
          if (code === 200) {
            this.$message.success('操作成功');
            this.handleClose();
            this.$emit('success');
            // this.getDataList(true);
          } else {
            this.$message.error(message);
          }
        }
      });
    },
    getTypeList(value) {
      this.addForm.carSeriesArr = undefined;
      this.addForm.dealerArr = undefined;
      if (!value) return (this.carSeriesIdList = []);
      return new Promise((resolve) => {
        api.getNewTypeList({ principalIds: this.addForm.principalId }).then((res) => {
          if (res.code === 200) {
            this.carSeriesIdList = res.data;
            resolve(true);
          } else {
            this.$message.error(res.message);
          }
        });
      });
    },
    onAreaChange(value) {
      this.addForm.cityArr = value;
    },
    handleStoreChange(val) {
      if (val.length > 0) {
        const currentList = this.allShopList.map((v) => v.id);
        val.forEach((v) => {
          if (!currentList.includes(v.id)) {
            this.allShopList.push(v);
          }
        });
      }
    },
    async getAreaData() {
      const treeList = [];
      // 省
      const level_1 = await this.$api.core.pphtManage.getLevelArea(1);
      level_1.forEach((value) => {
        treeList.push(this.treeItemFormat(value));
      });
      // 市
      const level_2 = await this.$api.core.pphtManage.getLevelArea(2);
      level_2.forEach((value) => {
        treeList.forEach((val) => {
          // 直辖市
          if (['110000', '120000', '310000', '500000'].some((code) => code === value.parent_id))
            return (value.children = null);
          if (val.key === value.parent_id)
            val.children.push(Object.assign({}, this.treeItemFormat(value), { children: null }));
        });
      });
      this.allAreaList = [...level_1, ...level_2];
      this.options = treeList;
    },
    areaFilter(inputValue, path) {
      return path.some((option) => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
    },
    displayRender({ labels }) {
      return labels[labels.length - 1];
    },
    treeItemFormat(value) {
      return {
        value: value.id,
        key: value.id,
        label: value.name,
        parentId: value.parent_id || value.parentId,
        level: value.level,
        selectable: false,
        children: [],
      };
    },
  },
};
</script>

<style lang="less" scoped></style>
